<template>
    <div class="p-5 text-gray-300 overflow-scroll">
        <div class="flex items-center justify-between mb-2">
            <div class="flex items-center w-3/5">
                <div class="form-feedback form-feedback-right w-full">
                    <input v-model="search" ref="search"  type="text" class="form-control bg-black placeholder-gray-600 border-gray-700" placeholder="search order or customer name">
                    <div v-show="!search" class="form-control-feedback">
                        <span>
                            <i class="icon-search4 text-gray-500"></i>
                        </span>
                    </div>
                    <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                        <a @click.prevent="search = ''" href="#">
                            <i class="icon-cancel-circle2 text-red-500"></i>
                        </a>
                    </div>
                    <div v-show="filters.length > 0" ref="filterSearch" class="absolute inset-y-0 my-1 left-1 flex items-center rounded-l-sm overflow-hidden bg-gray-800">
                        <div class="bg-gray-700 h-full flex items-center text-gray-100 px-1"><i class="icon-filter3" style="font-size: 12px"></i></div>
                        <div class="border border-l-0 border-gray-600 h-full flex items-center px-1">
                            <ul class="flex space-x-1">
                                <li v-for="(filter, i) in filters" :key="i">{{filter}}
                                    <span v-show="filters.length > 1 && filters.length > i+1" class="italic">or</span>
                                </li>
                                <li class="flex items-center">
                                    <a @click="clearFilter" href="#" class="flex hover:text-red-500"><i class="icon-cross3"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div ref="filter" class="ml-2 relative">
                    <button @click="showFilter = !showFilter" type="button" class="bg-black border-gray-700 border whitespace-nowrap relative rounded-sm h-9 w-full focus:outline-none hover:bg-gray-900">
                        <div class="relative h-full flex items-center">
                            <div class="h-full w-9 bg-gray-800 rounded-l-sm flex justify-center items-center">
                                <i class="icon-filter3"></i>
                            </div>
                            <div class="pl-3">
                                <span class="text-gray-500 pr-5">Filter</span>
                            </div>
                            <div class="h-9 w-9 flex items-center justify-center ml-auto"> 
                                <i class="icon-arrow-down22"></i>
                            </div>
                        </div>
                    </button>
                    <div v-show="showFilter" class="absolute bg-gray-800 border-gray-700 border mt-0.5 w-full">
                        <ul class="my-1">
                            <li v-for="(filter, i) in filterStatus" :key="i" class="hover:bg-gray-700">
                                <a @click="filter.status = !filter.status" href="#" class="flex items-center py-1 px-1">
                                    <div class="flex w-4">
                                        <i v-show="filter.status" class="icon-checkmark2 text-teal-500"></i>
                                    </div>
                                    <div class="ml-1" :class="{'font-medium': filter.status}">{{filter.text}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
               <div class="flex items-center">
                    <div class="flex items-center mr-10">
                        <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                        <div class="flex items-center space-x-1">
                            <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                            <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                        </div>
                    </div>
                    <button class="h-7 w-7 bg-gray-800 border border-gray-700  -mr-px"><i class="icon-grid6"></i></button>
                    <button class="h-7 w-7 bg-gray-800 border border-gray-700"><i class="icon-list2"></i></button>
                </div>
            </div>
        </div>

        <div class="rounded-sm overflow-hidden">
            <table class="w-full">
                <thead>
                    <tr class="bg-gray-800 border border-gray-700">
                        <th class="py-2 pl-5 pr-2 text-gray-400" style="width: 15%">ORDER #</th>
                        <th class="py-2 px-2 text-gray-400" style="width: 35%">CUSTOMER NAME</th>
                        <th class="py-2 px-2 text-gray-400" style="width: 15%">PAYMENT METHOD</th>
                        <th class="py-2 px-2 text-gray-400 text-right" style="width: 20%">TOTAL</th>
                        <th class="py-2 px-2 text-gray-400" style="width: 15%">DATE CREATED</th>
                        <th class="py-2 px-2 text-gray-400 text-center" style="width: 15%">ACTION</th>
                    </tr>
                </thead>
                <tbody class="bg-black">
                    <tr v-for="(order, i) in orders" :key="i" class="border border-gray-700" :class="{'bg-gray-900': i%2 == 0}">
                        <td class="py-2 pl-5 pr-2">
                            {{order.posNo}}
                        </td>
                        <td class="py-2 px-2">{{order.customer}}</td>
                        <td class="py-2 px-2">{{order.paymentMethod}}</td>
                        <td class="py-2 px-2 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(order.grandTotal)}}</td>
                        <td class="py-2 px-2">{{getDate(order.createdAt)}}</td>
                        <td class="py-2 px-1 text-center">
                            <a @click="onClickPrint(order.invoiceId, order.posNo, order.customer)" href="#" class="hover:text-red-500"><i class="icon-printer"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Modal Printer -->
        <modal-center :show="modalPrint">
            <div class=" w-2/5 bg-white rounded-sm p-10">
                <div class="flex items-center justify-center mb-5">
                    <div class="h-28 w-28 rounded-full border-4 flex items-center border-gray-500 justify-center text-gray-500">
                        <i class="icon-printer icon-5x"></i>
                    </div>
                </div>
                <div class="text-2xl text-green-500 text-center mb-5">PRINT INVOICE</div>
                <div class="text-gray-400 mb-5">
                    <div class="font-semibold">Sales Order</div>
                    <div class="text-3xl font-semibold text-gray-600">{{formPrint.salesNo}}</div>
                </div>
                <div class="text-gray-400 mb-5">
                    <div class="font-semibold">Customer</div>
                    <div class="text-xl font-semibold text-gray-600">{{formPrint.customerName}}</div>
                </div>
                <div class=" flex items-center justify-center text-gray-500 space-x-2">
                    <button @click="onPrintStruk" class="border-2 border-gray-600 rounded-sm h-9 px-5 relative">
                        <div v-if="isDisabledStruk" class="absolute bg-teal-400 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                        Print Thermal
                    </button>
                    <button @click="onPrint" class="border-2 border-green-500 rounded-sm h-9 px-5 relative">
                        <div v-if="isDisabled" class="absolute bg-teal-400 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                        Print A4 Invoice
                    </button>
                    <button @click="onCancelPrint" class="border-2 border-red-500 rounded-sm h-9 px-5">DISCARD</button>
                </div>
            </div>
        </modal-center>
        <div class=" hidden">
            <invoice-pdf :data="invoice" id="printJS-form"></invoice-pdf>
        </div>
        <div class="hidden">
            <struk-pdf :data="struk" id="print-Struk"></struk-pdf>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce'
import axios from 'axios'
import {moment} from '../../moment'
import ModalCenter from '../../components/ModalCenter.vue'
import printJS from 'print-js'
import invoicePdf from '../../components/invoicePdf.vue'
import strukPdf from '../../components/struk.vue'
export default {
    components: {ModalCenter, invoicePdf, strukPdf},
    data () {
        return {
            search: '',
            modalPrint: false,
            showFilter: false,
            filterStatus: [
                {status: false, text: 'Cash'},
                {status: false, text: 'Debit Card'},
                {status: false, text: 'Transfer Bank'},
                {status: false, text: 'Tokopedia'},
                {status: false, text: 'Multiple'},
            ],
            pages: '',
            searchData: '',
            orders: [],
            formPrint: {
                salesNo: '',
                customerName: ''
            },
            isDisabled: false,
            isDisabledStruk: false,
            invoiceId: '',
            invoice: {
                invoice: {
                    shipping: ''
                }
            },
            struk: ''
        }
    },
    mounted () {
        this.getData()
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
        document.addEventListener('click', this.closeFilter)
        let w = this.$refs.filterSearch.clientWidth + 11
        this.$refs.search.style.paddingLeft = w+'px'
    },
    destroyed () {
        document.removeEventListener('click', this.closeFilter)
    },
    computed: {
        filters () {
            let data = []
            for (let i=0; i<this.filterStatus.length; i++) {
                if (this.filterStatus[i].status == true) {
                    data.push(this.filterStatus[i].text)
                }
            }
            return data
        }
    },
    methods: {
        getData (e) {
            axios.get('/pos', {
                params: {
                    page: e,
                    search: this.search,
                    filters: this.filters
                }
            })
            .then(res => {
                this.orders = res.data.data
                this.pages = res.data.pages
            })
        },
        getDate (d) {
            return moment(d)
        },
        clearFilter () {
            for (let i=0; i < this.filterStatus.length; i++) {
                this.filterStatus[i].status = false
            }
        },
        closeFilter (e) {
            if(!e || !this.$refs.filter.contains(e.target)) {
                this.showFilter = false
            }
        },
        onClickPrint (invoiceId, so, cs) {
            this.modalPrint = true
            this.formPrint.salesNo = so
            this.formPrint.customerName = cs
            this.invoiceId = invoiceId._id
        },
        onPrint () {
            this.isDisabled = true
            axios.get('/invoices/detail/'+this.invoiceId)
            .then(res => {
                this.invoice = res.data
                let invoiceNo = res.data.invoice.invoiceNo
                if(!this.invoice.invoice.shipping) {
                    this.invoice.invoice.shipping = {
                        shipmentMethodId: '',
                        shipmentService: '',
                        shipmentCost: 0,
                        bookingCode: '',
                        trackingNumber: '',
                    }
                }
                let title = invoiceNo.replace(/\//g, ".") +" ("+ res.data.invoice.billTo.customer.name+")"
                document.title = title
                this.$nextTick(() => {
                    printJS({
                        printable: 'printJS-form', 
                        type: 'html',
                        css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                        scanStyles: false,
                        onPrintDialogClose: () => this.onCancelPrint()
                    })
                })
            })
        },
        onPrintStruk () {
            this.isDisabledStruk = true
            axios.get('/invoices/detail/'+this.invoiceId)
            .then(res => {
                this.struk = res.data
                let invoiceNo = res.data.invoice.invoiceNo
                let title = invoiceNo.replace(/\//g, ".") +" ("+ res.data.invoice.billTo.customer.name+")"
                document.title = title
                this.$nextTick(() => {
                    printJS({
                        printable: 'print-Struk', 
                        type: 'html',
                        css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                        scanStyles: false,
                        onPrintDialogClose: () => this.onCancelPrint()
                    })
                })
            })
        },
        onCancelPrint () {
            this.modalPrint = false
            document.title = 'APP DUTAPERSADA'
            this.isDisabled = false
            this.isDisabledStruk = false
        }
    },
    watch: {
        search () {
            this.searchData()
        },
        filterStatus: {
            handler: function () {
                this.$nextTick(() => {
                    let w = this.$refs.filterSearch.clientWidth + 11
                    this.$refs.search.style.paddingLeft = w+'px'
                    this.getData()
                })
            },  
            deep: true
        }
    }
}
</script>